(function(i, p, w, d, b, $, c, t){
    var TNT_Paw_Ad = {
        'el': {
            'pencil': {
                'ad_wrapper': '#tn-pencil-container',
                'ad_main': '.tn-pencil',
                'ad_strip': '.tn-pencil-strip',
                'ad_billboard': '.tn-pencil-billboard',
                'ad_toggle': '.tn-pencil-toggle',
                'ad_closed': '.tn-pencil-closed',
                'ad_open': '.tn-pencil-open'
            },
            'wallpaper': {
                'ad_container': '#tn-wallpaper-container',
                'ad_grid': '.tn-wallpaper-grid',
                'ad_grid_buffer': '.tn-wallpaper-grid-buffer',
                'ad_grid_item': '.tn-wallpaper-grid-item'
            }
        },
        'ad_css': function() {
            var css = '',
                maxWidth = 1170;
            if (this.settings.page_width == '1366') maxWidth = 1366;
            css += '<style>';
                if (this.settings.pencil || this.settings.pencil_mobile) {
                    css += '#fixed-impact-top { margin-bottom: 0; }'+
                    this.el.pencil.ad_wrapper + ' { background-color: transparent; text-align: center; z-index: 1; position: relative; }';
                    if (this.settings.pencil_background_color) {
                        css += this.el.pencil.ad_wrapper + ' { background-color: #' + this.settings.pencil_background_color.replace('#','') + '; }';
                    }
                    css += this.el.pencil.ad_wrapper + ' img { margin: 0 auto; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_main + ' { padding: 0; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_strip + ' { position: relative; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_billboard + ' { max-height: 0; overflow: hidden; -webkit-transition: max-height 0.8s; -moz-transition: max-height 0.8s; transition: max-height 0.8s; }' +
                    this.el.pencil.ad_wrapper + '.in ' + this.el.pencil.ad_billboard + ' { max-height: 500px; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' { position: absolute; font-family: arial; color: #000; font-size: 14px; line-height: 50px; height: 50px; font-weight: bold; right:0; width: 100px; text-align: center; cursor: pointer; }';
                    if (this.settings.pencil_button_color) {
                        css += this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' { color: #' + this.settings.pencil_button_color.replace('#','') + '; }';
                    }
                    css += this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toogle + ':hover { opacity: 0.8; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' .tnt-svg { opacity: 0.5; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_closed + ' { display: block; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); }' +
                    this.el.pencil.ad_wrapper + '.in ' + this.el.pencil.ad_closed + ' { display: none; }' +
                    this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_open + ' { display: none; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); }' +
                    this.el.pencil.ad_wrapper + '.in ' + this.el.pencil.ad_open + ' { display: block; }' +
                    '.drawer-open ' + this.el.pencil.ad_wrapper + ' { display: none; }' +
                    '@media (max-width: 1199px) {'+
                        this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' { line-height: 41px; height: 41px; width: 84px; }' +
                    '}' +
                    '@media (max-width: 991px) {' +
                        this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' { line-height: 32px; height: 32px; width: 65px; }' +
                        this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' .tnt-svg { display: none; }' +
                    '}'+
                    '@media screen and (max-width: 767px) {' +
                        this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' { width: auto; height: 100%; padding: 3px 8px; bottom: 0; }' +
                        this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' .tnt-svg { display: block; font-size: 25px; }' +
                        this.el.pencil.ad_wrapper + ' ' + this.el.pencil.ad_toggle + ' .tn-pencil-toggle-text { display: none; }' +
                    '}';
                }
                if (this.settings.background_left && this.settings.background_right && !this.settings.multipleWallpapers) {
                    css += this.el.wallpaper.ad_container +' { position: fixed; top: 0; left: 0; width: 100%; height: 100vh; }' +
                    this.el.wallpaper.ad_grid + ' { display: -ms-grid; display: grid; -ms-grid-rows: 100vh; grid-template-rows: 100vh; }' +
                    '@media (min-width: 768px) { ' + this.el.wallpaper.ad_grid + ' { -ms-grid-columns: 1fr 750px 1fr; grid-template-columns: 1fr 750px 1fr; } }' +
                    '@media (min-width: 992px) { ' + this.el.wallpaper.ad_grid + ' { -ms-grid-columns: 1fr 970px 1fr; grid-template-columns: 1fr 970px 1fr; } }' +
                    '@media (min-width: 1200px) { ' + this.el.wallpaper.ad_grid + ' { -ms-grid-columns: 1fr 1170px 1fr; grid-template-columns: 1fr 1170px 1fr; } }' +
                    '@media (min-width:' + (maxWidth + 30) +'px) { ' + this.el.wallpaper.ad_grid + ' { -ms-grid-columns: 1fr ' + maxWidth + 'px 1fr; grid-template-columns: 1fr ' + maxWidth + 'px 1fr; } }' +
                    this.el.wallpaper.ad_grid_buffer + ' { -ms-grid-column: 2; -ms-grid-row: 1; }' +
                    this.el.wallpaper.ad_grid_item + '.left { display: block; -ms-grid-column: 1; -ms-grid-row: 1; background: url(' + this.settings.background_left + ') no-repeat; background-size: auto; }' +
                    this.el.wallpaper.ad_grid_item + '.right { display: block; -ms-grid-column: 3; -ms-grid-row: 1; background: url(' + this.settings.background_right + ') no-repeat; background-size: auto; }' +
                    '#site-container { padding: 0!important; }' +
                    '.drawer-open ' + this.el.wallpaper.ad_container + ', .modal-open ' + this.el.wallpaper.ad_container + '{ display: none; }' +
                    '.modal-open #site-container { position: static; }' +
                    '.drawer-open #site-container { position: static; width: auto; padding: 0; margin: 0; }';
                    if (this.settings.clipping === 'Outside') {
                        css += this.el.wallpaper.ad_grid_item + '.left { background-position: top right; }' +
                        this.el.wallpaper.ad_grid_item + '.right { background-position: top left; }';
                    } else if (this.settings.clipping === 'Inside') {
                        css += this.el.wallpaper.ad_grid_item + '.left { background-position: top left; }' +
                        this.el.wallpaper.ad_grid_item + '.right { background-position: top right; }';
                    }
                    if (this.settings.background_color) {
                        css += this.el.wallpaper.ad_grid_item + '.left, ' + this.el.wallpaper.ad_grid_item + '.right { background-color: #' + this.settings.background_color.replace('#','') + '; }';
                    }
                }
            css += '</style>';
            return css;
        },
        'ad_pencil_html': function() {
            var html = '';
            if (this.settings.pencil || this.settings.pencil_mobile) {
                html += '<div id="' + this.el.pencil.ad_wrapper.replace('#','') + '">' +
                    '<div class="' + this.el.pencil.ad_main.replace('.','') + ' container">' +
                        '<div class="' + this.el.pencil.ad_strip.replace('.', '') + '">';
                            if (this.settings.billboard || this.settings.billboard_mobile) {
                                html += '<div class="' + this.el.pencil.ad_toggle.replace('.','') + '">' +
                                    '<span class="' + this.el.pencil.ad_closed.replace('.','') + '"><i class="fas tnt-chevron-circle-down"></i> <span class="tn-pencil-toggle-text">Expand</span></span>' +
                                    '<span class="' + this.el.pencil.ad_open.replace('.','') + '"><i class="fas tnt-chevron-circle-up"></i> <span class="tn-pencil-toggle-text">Close</span></span>' +
                                '</div>';
                            }
                            html += '<a href="' + this.settings.click_tag + '" target="_blank" rel="noopener">';
                                if (this.settings.pencil) {
                                    html += '<img class="img-responsive hidden-xs" src="' + this.settings.pencil + '" border="0">';
                                }
                                if (this.settings.pencil_mobile) {
                                    html += '<img class="img-responsive visible-xs" src="' + this.settings.pencil_mobile + '" border="0">';
                                }
                            html += '</a>' +
                        '</div>';
                        if (this.settings.billboard || this.settings.billboard_mobile) {
                            html += '<div class="' + this.el.pencil.ad_billboard.replace('.','') + '">' +
                                '<a href="' + this.settings.click_tag + '" target="_blank" rel="noopener">';
                                    if (this.settings.billboard) {
                                        html += '<img class="img-responsive hidden-xs" src="' + this.settings.billboard + '" border="0">';
                                    }
                                    if (this.settings.billboard_mobile) {
                                        html += '<img class="img-responsive visible-xs" src="' + this.settings.billboard_mobile + '" border="0">';
                                    }
                                html += '</a>' +
                            '</div>';
                        }
                    html += '</div>' +
                '</div>';
            }
            if (this.settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1" /></div>';
            }
            return html;
        },
        'ad_wallpaper_html': function() {
            var html = '';
            html += '<div id="' + this.el.wallpaper.ad_container.replace('#','') + '" class="hidden-xs">' +
                '<div class="' + this.el.wallpaper.ad_grid.replace('.','') + '">' +
                    '<a href="' + this.settings.click_tag + '" class="' + this.el.wallpaper.ad_grid_item.replace('.','') +' left" target="_blank" rel="noopener"></a>' +
                    '<div class="' + this.el.wallpaper.ad_grid_buffer.replace('.','') + '"></div>' +
                    '<a href="' + this.settings.click_tag + '" class="' + this.el.wallpaper.ad_grid_item.replace('.','') + ' right" target="_blank" rel="noopener"></a>' +
                '</div>' +
            '</div>';

            return html;
        },
        'load': function(settings) {
            this.settings = settings;

            if (this.settings.background_left && this.settings.background_right && d.getElementById('tn-wallpaper-container')) {
                this.settings.multipleWallpapers = true;
                w.console.warn('Existing wallpaper ad detected. PAW wallpaper ad canceled.');
            }

            var that = this,
                expandClicked = false;

            $(d).find(p).hide();
            $(d).find(p).parent().prepend(this.ad_css());
            $(d).find(p).parent().prepend(this.ad_pencil_html());
            $(d).find(p).parents('.tnt-ads').width('auto');

            var $insertedAd = $(d).find(p).parent().find(this.el.pencil.ad_wrapper);

            $insertedAd.find('.tn-pencil-toggle').on('click', function() {
                $insertedAd.toggleClass('in');
                expandClicked = true;
            });

            if (this.settings.background_left && this.settings.background_right && !this.settings.multipleWallpapers) {
                $(b).find('#site-container').addClass('container');
                $(b).prepend(this.ad_wallpaper_html());
            }

            if (!c.get('tncms-ads-pencil-expand') && this.settings.auto_expand === 'true') {
                var xExpTime = new Date();
                xExpTime.setTime(xExpTime.getTime() + (12 * 60 * 60 * 1000));
                setTimeout(function(){
                    $insertedAd.addClass('in');
                    c.set('tncms-ads-pencil-expand', 1, { path:'/', expires:xExpTime });
                    if (that.settings.auto_expand_close && that.settings.auto_expand_close > 0){
                        var xPencilTimer = parseInt(that.settings.auto_expand_close);
                        if (xPencilTimer < 3 || xPencilTimer > 100) {
                            xPencilTimer = 2;
                        }
                        xPencilTimer = (xPencilTimer * 1000);
                        setTimeout(function(){
                            if (!expandClicked) {
                                $insertedAd.removeClass('in');
                            }
                        }, xPencilTimer);
                    }
                }, 500);
            }
        },
    };

    try {
        if (!i) {
            throw 'Friendly iframe required.';
        }
        $(t.cmds).each(function() {
            TNT_Paw_Ad.load(this.call());
            return false;
        });
    }
    catch(e){
        if (w.console) {
            w.console.warn(e);
        }
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.inDapIF ? window.parent.jQuery : jQuery),
    (window.inDapIF ? window.parent.Cookies : Cookies),
    (window.__TNT_AD || {})
);